import React, { useRef, useState, useEffect } from 'react'
import * as fnc from 'helpers/fnc'
import noUiSlider from 'nouislider'
import { Dict, CustomEventType } from 'app/types'
import { Button } from './Button'
import ReactSlider from 'react-slider'

/**
 * @param slider HtmlElement with an initialized slider
 * @param threshold Minimum proximity (in percentages) to merge tooltips
 * @param separator String joining tooltips
 */
export function mergeTooltips(slider, threshold, separator) {
    const textIsRtl = getComputedStyle(slider).direction === 'rtl'
    const isRtl = slider.noUiSlider.options.direction === 'rtl'
    const isVertical = slider.noUiSlider.options.orientation === 'vertical'
    const tooltips = slider.noUiSlider.getTooltips()
    const origins = slider.noUiSlider.getOrigins()

    // Move tooltips into the origin element. The default stylesheet handles this.
    tooltips.forEach(function (tooltip, index) {
        if (tooltip) {
            origins[index].appendChild(tooltip);
        }
    })

    slider.noUiSlider.on('update', function (values, handle, unencoded, tap, positions) {
        const pools = [[]]
        const poolPositions = [[]]
        const poolValues = [[]]
        let atPool = 0;

        // Assign the first tooltip to the first pool, if the tooltip is configured
        if (tooltips[0]) {
            pools[0][0] = 0;
            poolPositions[0][0] = positions[0];
            poolValues[0][0] = values[0];
        }

        for (var i = 1; i < positions.length; i++) {
            if (!tooltips[i] || (positions[i] - positions[i - 1]) > threshold) {
                atPool++
                pools[atPool] = []
                poolValues[atPool] = []
                poolPositions[atPool] = []
            }

            if (tooltips[i]) {
                pools[atPool].push(i)
                poolValues[atPool].push(values[i])
                poolPositions[atPool].push(positions[i])
            }
        }

        pools.forEach(function (pool, poolIndex) {
            var handlesInPool = pool.length

            for (var j = 0; j < handlesInPool; j++) {
                var handleNumber = pool[j]

                if (j === handlesInPool - 1) {
                    var offset = 0

                    poolPositions[poolIndex].forEach(function (value) {
                        offset += 1000 - 10 * value
                    })

                    var direction = isVertical ? 'bottom' : 'right'
                    var last = isRtl ? 0 : handlesInPool - 1
                    var lastOffset = 1000 - 10 * poolPositions[poolIndex][last]
                    offset = (textIsRtl && !isVertical ? 100 : 0) + (offset / handlesInPool) - lastOffset

                    // Center this tooltip over the affected handles
                    tooltips[handleNumber].innerHTML = poolValues[poolIndex].join(separator)
                    tooltips[handleNumber].style.display = 'block'
                    tooltips[handleNumber].style[direction] = offset + '%'
                } else {
                    // Hide this tooltip
                    tooltips[handleNumber].style.display = 'none';
                }
            }
        })
    })
}
interface SliderProps {
    className?: string,
    min: number,
    startMin?: number,
    max: number,
    startMax?: number,
    style?: Dict,
    prefix?: string,
    suffix?: string,
    step?: number,
    onChange: () => void
    close?: boolean,
    simple: boolean,
    value: boolean,
}

const defaultProps = {
    min: 0,
    startMin: 0,
    max: 100,
    startMax: 0,
    step: 100,
}

export function Slider(props: SliderProps) {
    const {
        className,
        onChange,
        min,
        startMin,
        max,
        startMax,
        prefix,
        suffix,
        step,
        close,
        simple,
        value,
    } = { ...defaultProps, ...props }
    const sliderEl = useRef(null)
    const attrs = `${className ? ` ${className}` : ''}`
    const initialized = useRef(false)
    const [modified, setModified] = useState(false)
    const changeTimeout = useRef(null)

    useEffect(() => {
        if (initialized.current) {
            return
        }
        if (!simple) {
            noUiSlider.create(sliderEl.current, {
                start: [step * Math.floor(startMin / step), step * Math.ceil(startMax / step)],
                connect: true,
                tooltips: [true, true],
                range: {
                    min: [step * Math.floor(min / step), step],
                    max: [step * Math.ceil(max / step), step],
                },
                format: {
                    to: (value) => `${prefix != null ? prefix : ''}${fnc.numberWithCommas(Math.round(value))}${suffix != null ? suffix : ''}`,
                    from: (value) => value,
                },
            })

            sliderEl.current.noUiSlider.on('set', function (values, handle) {
                var value = values[handle]
                let [newMin, newMax] = values.map(x => parseInt(x.replace(prefix, '').replace(suffix, '').replace(/,/g, '')))
                setModified(newMin != min || newMax != max)
                if (onChange) {
                    onChange(newMin, newMax)
                }
            })

            mergeTooltips(sliderEl.current, 40, ' - ')

            if (min !== startMin || max !== startMax) {
                setModified(true)
            }
        }
        initialized.current = true
    }, [])


    function resetSlider() {
        sliderEl.current?.noUiSlider.set([step * Math.floor(min / step), step * Math.ceil(max / step)])
        onChange(min, max)
        setModified(false)
    }

    function handleSimpleChange(value) {
        clearTimeout(changeTimeout.current)
        changeTimeout.current = setTimeout(() => {
            onChange(value)
        }, 100)
    }

    function closeMenus() {
        window.dispatchEvent(new Event(CustomEventType.CloseAllMenus))
    }
    if (simple) {
        const step = 100.0 / (max - min)
        return <div className={`slider simple noselect${attrs}`} onClick={(e) => e.stopPropagation()}>
            {/* <input type="range" min={min} max={max} value={value} step={step} onChange={(e) => handleSimpleChange(e.target.value)} /> */}
            <ReactSlider
                className={`horizontal-slider`}
                marks
                markClassName="slider-mark"
                defaultValue={value != null ? value : min}
                min={min}
                max={max}
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                onChange={(value, index) => handleSimpleChange(value)}
                renderMark={(props, state) => {
                    const left = `${props.key * step}%`
                    return <div {...props} style={{ ...props.style, left }} />
                    // return <div key={props.key} className="slider-mark" style={{ position: 'absolute', left: `${left}%` }} />
                }}
                renderThumb={(props, state) => {
                    const left = `${(state.value - min) * step}%`
                    return <div {...props} style={{ ...props.style, left }}>{state.valueNow}</div>
                }}
            />
            {close || modified && <div className="row">
                {modified && <Button style={{ margin: '25px auto 0 auto' }} onClick={resetSlider}>Reset</Button>}
                {close && <Button style={{ margin: '25px auto 0 auto' }} onClick={closeMenus}>Close</Button>}
            </div>}
        </div>
    }

    return (
        <div className={`slider noselect${attrs}`} onClick={(e) => e.stopPropagation()}>
            <div ref={sliderEl} />
            <div className="row">
                {modified && <Button style={{ margin: '25px auto 0 auto' }} onClick={resetSlider}>Reset</Button>}
                {close && <Button style={{ margin: '25px auto 0 auto' }} onClick={closeMenus}>Close</Button>}
            </div>
        </div>
    )
}
