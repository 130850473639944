import React, { useState, useEffect, useRef } from 'react'
import { CustomEventType } from 'app/types'
import {
    IconButton, Button, Input, CloseButton,
} from 'components'
import { useAppSelector } from 'app/hooks'
import { showPrompt } from 'actions/appActions'
import { icons } from 'app/constants'

type DropdownItem = {
    value?: string,
    text?: string,
    element?: JSX.element[],
    className: string,
}

interface DropdownProps {
    className?: string,
    icon?: string,
    openIcon?: string,
    text?: string,
    items: DropdownItem[],
    fadeIn?: boolean,
    right?: boolean,
    upwards?: boolean,
    value?: string,
    onChange?: () => string,
    style?: { [key: string]: string },
    contentStyle?: { [key: string]: string },
    contentAnimation?: string,
    close?: boolean,
    closeItems?: DropdownItem[],
    closeOnSelect: boolean,
    buttonClass?: string,
    hideOnEmpty?: boolean,
    scrollable?: boolean,
    depth?: number,
    alt?: boolean,
    onAdd?: () => void,
    onDelete?: () => void,
    addPlaceholder: string,
    addButton: string,
    closeOnAdd?: boolean,
    scrollToSelected: boolean,
    multi: boolean,
    selectText: string,
    asList: string,
    listLabel: string,
    search: boolean,
    counter: number,
    asGrid: boolean,
    gridSize: number,
    inputOverride: boolean,
}
export function DropdownMenu(props: DropdownProps) {
    const {
        className,
        icon,
        openIcon,
        text,
        items,
        right,
        upwards: up,
        fadeIn,
        style,
        contentStyle,
        onChange,
        contentAnimation,
        close,
        closeItems,
        value,
        buttonClass,
        hideOnEmpty,
        scrollable,
        depth,
        closeOnSelect,
        closeOnAdd,
        onAdd,
        onDelete,
        addPlaceholder,
        addButton,
        scrollToSelected,
        multi,
        selectText,
        asList,
        search,
        counter,
        listLabel,
        asGrid,
        gridSize,
        inputOverride,
    } = {
        depth: 1, gridSize: 3, asList: true, closeOnSelect: !multi, ...props,
    }

    if (items && items.length === 0 && hideOnEmpty) {
        return null
    }

    const screen = useAppSelector((state: RootState) => state.app.screen)
    const [open, setOpen] = useState(null)
    const [contentRef, setContentRef] = useState(null)
    const [scrollRef, setScrollRef] = useState(null)
    const [filter, setFilter] = useState(null)
    const mobileMode = screen.isMobile && asList

    function toggleMenu(e: Event) {
        e.stopPropagation()

        if (!open) {
            window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus, { detail: { depth } }))
        }

        setTimeout(() => {
            setOpen(open != null ? !open : true)
        }, 0)
    }

    function closeMenu(e) {
        if (e && e.detail && e.detail.depth != null && e.detail.depth !== depth) {
            return
        }
        if (open) {
            setOpen(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeMenu)
        window.addEventListener(CustomEventType.CloseAllMenus, closeMenu)
        return () => {
            window.removeEventListener('click', closeMenu)
            window.removeEventListener(CustomEventType.CloseAllMenus, closeMenu)
        }
    }, [open])

    useEffect(() => {
        if (scrollRef && contentRef) {
            contentRef.scrollTo(0, scrollRef.offsetTop + (search ? -40 : 0))
        }
    }, [contentRef, scrollRef])

    function handleSelect(x: string) {
        if (multi && (value != null || Array.isArray(value))) {
            const newValues = value ? [...value] : []
            const idx = newValues.findIndex((y) => y == x)
            if (idx != -1) {
                newValues.splice(idx, 1)
            } else {
                newValues.push(x)
            }
            if (onChange) {
                onChange(newValues)
            }
        } else {
            if (onChange) {
                onChange(x)
            }
            if (closeOnSelect) {
                closeMenu()
            }
        }
    }

    function handleAdd(x: string) {
        if (onAdd) {
            onAdd(x)
        }
        if (closeOnAdd) {
            closeMenu()
        }
    }

    function handleDelete(x) {
        if (onDelete) {
            onDelete(x)
        }
    }

    function handleFilter(x: string) {
        setFilter(x)
    }

    function handleOverride(x: string) {
        if (onChange) {
            onChange(x)
        }
    }

    function getContent(mobile = false) {
        if (!items) {
            return null
        }

        let elements = []

        let firstSelected = false
        const buildItem = (x: DropdownItem, ix: number) => {
            if (!x) {
                return null
            }
            if (x.element) {
                return <React.Fragment key={ix}>{x.element}</React.Fragment>
            }
            const hasId = x.value != null && typeof x.value === 'object' && ('id' in x.value || 'tempId' in x.value)
            let isSelected = false
            let scrollTo = false
            if (value != null) {
                if (Array.isArray(value)) {
                    if (hasId) {
                        isSelected = value.includes(x.value.id) || value.includes(x.value.tempId)
                    } else {
                        isSelected = value.includes(x.value)
                    }
                } else if (hasId) {
                    isSelected = value.id == x.value.id
                } else {
                    isSelected = value != null && typeof value == typeof x.value && value == x.value
                }
            }
            if (isSelected && !firstSelected) {
                scrollTo = true
                firstSelected = true
            }
            const key = hasId ? x.value.id : x.value
            if (mobile) {
                return <option selected={x.value == value} value={x.value}>{x.text}</option>
            }
            if (!x.text) {
                return <IconButton
                    onBg
                    listElement={asList}
                    forwardRef={scrollTo ? setScrollRef : null}
                    className={`${x.className ? x.className : ''} ${isSelected ? ' selected' : ''}`}
                    icon={x.icon}
                    key={key}
                    onClick={(e) => { e.stopPropagation(); handleSelect(x.value) }}>
                    {x.text}
                    {onDelete && x.value != null && x.value != 'add' && <IconButton icon={icons.times} className="delete" onClick={(e) => { e.stopPropagation(); handleDelete(x.value) }} noBg />}
                </IconButton>
            }
            return <Button
                listElement={asList}
                forwardRef={scrollTo ? setScrollRef : null}
                className={`${x.className ? x.className : ''} ${isSelected ? ' selected' : ''}`}
                icon={x.icon}
                key={key}
                onClick={(e) => { e.stopPropagation(); handleSelect(x.value) }}>
                {x.text}
                {onDelete && x.value != null && x.value != 'add' && <IconButton icon={icons.times} className="delete" onClick={(e) => { e.stopPropagation(); handleDelete(x.value) }} noBg />}
            </Button>
        }
        if (Array.isArray(items)) {
            if (search && filter != null) {
                elements = items.filter((x) => {
                    return x && x.text && x.text.toLowerCase().includes(filter.toLowerCase())
                }).map(buildItem)
            } else {
                elements = items.map(buildItem)
            }
        } else {
            elements = buildItem(items, 0)
        }

        if (onAdd) {
            if(addButton) {
                elements.unshift(<Button onClick={handleAdd}>{addButton}</Button>)
            } else {
                elements.push(<Input key="input" onSubmit={handleAdd} placeholder={addPlaceholder || 'Add'} clearOnSubmit />)
            }
        }

        if (search) {
            elements.unshift(<Input className="search" key="search" value={filter} onChange={handleFilter} placeholder={'Search'} clear />)
        }
        if (inputOverride) {
            elements.unshift(<Input className="search" key="override" value={value} onChange={handleOverride} placeholder={''} clear />)
        }
        return elements
    }

    const attrs = `${className ? ` ${className}` : ''}${open ? ' open' : ''}${asList ? ' as-list' : ''}${mobileMode ? ' mobile' : ''}`
    const contentAttrs = `${right ? ' right' : ''}${up ? ' upwards' : ''}${fadeIn ? ' fadeIn' : ''}${contentAnimation != null ? ` animate__animated  animate__fastest animate__${contentAnimation}` : ''}${scrollable ? ' scrollable' : ''}${asGrid ? ` as-grid grid-size-${gridSize}` : ''}`

    let iconAnimation = ''
    if (open != null && openIcon != null) {
        iconAnimation = `animate__animated animate__fastest ${open ? 'animate__rotateInNoFade' : 'animate__rotateOutNoFade'}`
    }

    let finalText = null
    let valueSet = true
    if (text) {
        finalText = text
    } else if (value != null || value === 0) {
        if (Array.isArray(value)) {
            if (value.length > 0) {
                finalText = items.filter((x) => {
                    const hasId = x.value != null && typeof x.value === 'object' && ('id' in x.value || 'tempId' in x.value)
                    if (hasId) {
                        return value.includes(x.value.id || x.value.tempId)
                    }
                    return value.includes(x.value)
                }).map((x) => x?.text).join(', ')
            } else {
                finalText = selectText || 'Select'
                valueSet = false
            }
        } else {
            finalText = items.find((x) => x.value == value)?.text
        }
    } else if (!icon) {
        finalText = selectText || 'Select'
        valueSet = false
    }

    let mobileElem = null
    if (mobileMode) {
        let showSelectOne = value == null && text == null
        mobileElem = <select data-value={value} onClick={(e) => e.stopPropagation()} onChange={(e) => {
            handleSelect(items[e.target.selectedIndex + (showSelectOne ? -1 : 0)].value)
        }}>
            {showSelectOne && listLabel && <option value="" disabled selected>Select {listLabel}</option>}
            {showSelectOne && !listLabel && <option value="" disabled selected>Select One</option>}
            {getContent(true)}
        </select>
        /*return <select className={`dropdown-menu${attrs}${valueSet ? ' value-set' : ''}`} style={style} data-value={value} onClick={(e) => e.stopPropagation()} onChange={(e) => {
            handleSelect(items[e.target.selectedIndex].value)
        }}>
            {listLabel && value == null && <option value="" disabled selected>Select {listLabel}</option>}
            {!listLabel && value == null && <option value="" disabled selected>Select One</option>}
            {getContent(true)}
        </select>*/
        attrs
    }
    const fullButton = finalText != null && (!icon || text)

    return <div className={`dropdown-menu${attrs}${valueSet ? ' value-set' : ''}`} style={style} data-value={value}>
        {mobileElem}
        {fullButton && <Button
            className={buttonClass != null ? buttonClass : ''}
            onClick={toggleMenu}
            onMouseDown={(e) => e.stopPropagation()}
            icon={icon}
            listElement={asList}>
            <React.Fragment>{finalText}<div className={icons.chevronDown}></div></React.Fragment>
        </Button>}
        {!fullButton && <IconButton
            className={`${buttonClass != null ? buttonClass : ''}${open ? ' selected' : ''}`}
            animation={iconAnimation}
            icon={openIcon && open ? openIcon : icon}
            onClick={toggleMenu} />}
        {counter && <div className="bottom-right counter">
            {items.filter((x) => x.count != false).length}
        </div>}
        {/* {open && <div className={`dropdown-content scrollable${contentAttrs}`} style={contentStyle} onMouseDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()} ref={setContentRef}> */}
        {open && <ul className={`dropdown-content scrollable${contentAttrs}`} style={contentStyle} onMouseDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()} ref={setContentRef}>
            {getContent()}
            {close && <div className="dropdown-sticky">{closeItems}<Button onClick={closeMenu}>Close</Button></div>}
        </ul>
        }
    </div>
}
