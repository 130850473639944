import React, { useRef } from 'react'
import {
    Media,
    TopBar,
    BreadCrumbs,
    OptionBar,
} from 'components'
import { AppData } from 'app/types'
import PinchZoomPan from 'components/PinchZoomPan/PinchZoomPan'
import { useAppSelector } from 'app/hooks'
import { projectContent } from 'app/transformers'

interface NeighbourhoodPageProps {
    app: AppData,
    breadCrumbs: boolean,
    splitIndex: number,
    onlyFavourites?: boolean,
}

export function NeighbourhoodPage(props: NeighbourhoodPageProps) {
    const {
        app,
        breadCrumbs,
        splitIndex,
        onlyFavourites,
    } = props

    const organization = useAppSelector((state: RootState) => state.app.organization)
    const viewRef = useRef()
    const crumbsRef = useRef()

    if (!app) {
        return null
    }

    function getBefore() {
        return <OptionBar app={app} splitIndex={splitIndex} titleText={app?.meta.name} />
    }

    function getNeighbourhoodView() {
        switch (app.meta.link) {
            default:
                const ContentComponent = projectContent(app)
                if (ContentComponent) {
                    return <ContentComponent app={app} organization={organization} link={'neighborhood'} standalone />
                }
                break
            case 'kingsleysquare': {
                // Neighbourhood elements
                const maxZoom = 3
                const autoFitBuffer = 0
                return <React.Fragment>
                    <PinchZoomPan options={[]} position='center' maxZoom={maxZoom} autoFitBuffer={autoFitBuffer} zoomButtons={true} zoomInc={0.5}>
                        <Media app={app} mediaId="kingsleysquareamenities_map" onClick={(e: Event) => e.stopPropagation()} spinner fadeIn fadeInDelay={500} />
                    </PinchZoomPan>
                    <div className="neighbourhood-legend-wrapper scrollable">
                        <div className="neighbourhood-legend">
                            <Media app={app} mediaId="kingsleysquareamenities_legendA" />
                        </div>
                        <div className="neighbourhood-legend">
                            <Media app={app} mediaId="kingsleysquareamenities_legendB" />
                        </div>
                    </div>
                </React.Fragment>
            }
        }
        return null
    }

    const contentStyle = {}
    if (crumbsRef.current) {
        contentStyle.maxHeight = `calc(100% - ${crumbsRef.current.clientHeight + 10}px)`
    }

    return <React.Fragment>
        {getBefore()}
        <div id="neighbourhood-view" className="content" style={{ overflow: 'hidden' }}>
            {breadCrumbs && <BreadCrumbs app={app} splitIndex={splitIndex} forwardRef={crumbsRef} />}
            <div className="content-wrapper" ref={viewRef} style={contentStyle}>
                {getNeighbourhoodView()}
            </div>
        </div>
    </React.Fragment>
}
