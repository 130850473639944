import {
    createSlice,
    createAsyncThunk,
    // PayloadAction,
} from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import {
    getAnalytics,
    getApp,
    putPricing,
    putAvailability,
    putWorksheets,
} from 'services/appServices'
import {
    getAdmin,
    getData,
    putData,
    postData,
    deleteData,
    postMediaFiles,
    putMedia,
    deleteMedia,
    postStartJob,
    getJob,
    postAppData,
    putAppData,
    deleteAppData,
    postGallery,
    putGallery,
    deleteGallery,
    postMedia,
    postMediaTag,
    putMediaTag,
    deleteMediaTag,
    getMediaThumbnails,
    getMediaExport,
    putMediaAdditional,
    putMap,
    postMap,
    getMap,
    getLogList,
    getLog,
    postRunOperation,
    postContent,
    postLocation,
    deleteLocation,
    deleteContent,
    postMediaThumbnails,
    putOrganizationData,
    postOrganizationData,
    deleteOrganizationData,
} from 'services/adminServices'
import {
    AdminDelta,
    AdminView,
    Analytics,
    AdminState,
    ErrorMessage,
    NestedDict,
    RootState,
    PageType,
    MediaTagData,
    UrlOption,
    MediaData,
    SegmentOptions,
    MapData,
    CustomContentData,
    AppState,
    OrganizationData,
} from 'app/types'
import { getMediaLink } from 'helpers/media'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import {
    resolvePending,
    editApp,
    setPath,
    applyTheming,
    addProjectMedia,
    updateProjectMedia,
    deleteProjectMedia,
    updateAppContent,
    updateAppLocation,
    removeAppLocation,
    removeAppContent,
    updateGallery,
    updateProjectCurrent,
    updateOrganizationLocation,
    updatePromptLocation,
    updatePrompt,
} from './appActions'
import {
    updateUser,
} from './userActions'
import {
    retryOperation,
    setupApp,
    setupAppMaps,
    setupAppMeta,
    setupPages,
    setupUpgrades,
} from './actionHelpers'
import { putUser } from 'services/userServices'
import { Action } from '@babylonjs/core/Actions/action'

const debugErrors = process.env.DEBUG == 1
// const cookies = new Cookies()

/**
 * Initial state
 */
const initialState: AdminState = {
    changePending: false,
    delta: {},
    error: {},
    customDomain: {},
    lastSave: Date.now(),
    editInline: localStorage.getItem('editInline') == 'true',
    editContent: null
}

function saveState(state: AdminState) {
    localStorage.setItem('editInline', state.editInline ? 'true' : 'false')
}

/**
 * Create a default "delta" for admin changes
 */
function setDefaultDelta(state: AdminState) {
    const views = Object.keys(AdminView)
    for (let i = 0; i < views.length; i += 1) {
        const view: string = AdminView[views[i]]
        state.delta[view] = {}
    }
}
setDefaultDelta(initialState)

/**
 * Replace state data at matching id
 */
function removeFromList(id, data) {
    const newData = [...data]
    const index = newData.findIndex((x) => x.id === id)
    if (index !== -1) {
        newData.splice(index, 1)
        return newData
    }
    return data
}

/**
 * Async actions
 */

/**
 * Initialize the administrative page of the application
 */
export const initializeAdmin = createAsyncThunk<AdminState,
    { appLink: string, organization: OrganizationData }>(
        'admin/initializeAdmin',
        async ({ appLink, options }, { rejectWithValue, getState, dispatch }) => {
            const tryAdmin = () => {
                return getAdmin(options)
                    .then((x: AdminState) => {
                        if (!x.apps) {
                            throw new Error(x)
                        }
                        // if (!getState().app.config.colorScheme) {
                        if (getState().app.standaloneApp && getState().app.projectCurrent[0] != null) {
                            applyTheming(getState, dispatch, null, getState().app.projectCurrent[0].meta)
                        } else if (x.organization && !getState().admin.organization) {
                            applyTheming(getState, dispatch, x.organization)
                        }
                        // }
                        dispatch(setPath({ appLink }))
                        // Copy config from app
                        return { ...x, config: getState().app.config, media: getState().app.media }
                    })
            }
            return retryOperation(tryAdmin)
                .catch((e) => {
                    applyTheming(getState, dispatch, options.organization)
                    return rejectWithValue(e)
                })
        },
    )

/**
 * Retrieve single app, for viewing additional information
 */
export const initializeAdminProject = createAsyncThunk<AdminState,
    { appLink: string, organizationLink: string, splitIndex: string }>(
        'admin/initializeAdminProject',
        async ({ appLink, organizationLink }, { dispatch, getState, rejectWithValue }) => {
            const tryApp = () => getApp(appLink, organizationLink, false, true)
                .then((x) => {
                    if (!x || !x.meta) {
                        logger.error('Error loading app', x)
                        throw new Error('Error loading app')
                    }
                    dispatch(addProjectMedia(x.media))

                    // See if this app is part of any organizations
                    let organization = null
                    if (x.organizations && x.organizations.length) {
                        organization = getState().admin.config.organizations
                            .find((y) => y.id == x.organizations[0])
                    }

                    if (getState().app.standaloneApp || true) {
                        applyTheming(getState, dispatch, organization, x.meta)
                        return { ...x }
                    }
                    else if (organization) {//((organization && !organization.published)) {
                        applyTheming(getState, dispatch, organization)
                        return { organization, ...x }
                    }

                    applyTheming(getState, dispatch, null)
                    return { ...x }
                })
            return retryOperation(tryApp)
                .catch(rejectWithValue)
        },
    )

/**
 * Retrieve data for analytics charts
 */
export const retrieveAnalytics = createAsyncThunk<NestedDict,
    { app: AppData, type: Analytics, csv: boolean, options: Dict }, { state: RootState }>(
        'admin/retrieveAnalytics',
        async ({
            app,
            csv,
            type,
            options,
        }, { rejectWithValue }) => {
            const tryAnalytics = () => getAnalytics(app, type, options)
            return retryOperation(tryAnalytics)
                .catch(rejectWithValue)
        },
    )

/**
 * Retrieve data for management
 */
export const retrieveData = createAsyncThunk<NestedDict,
    { view: AdminView, options: Dict }, { state: RootState }>(
        'admin/retrieveData',
        ({ view, options }, { dispatch, rejectWithValue }) => {
            const tryData = () => getData(view, options)
                .then((x) => {
                    const key = view.singular()
                    if (key in x) {
                        // Extract media if relevant
                        const {
                            media,
                            ...other
                        } = x[key]
                        if (media) {
                            dispatch(addProjectMedia(media))
                        }

                        return { view, data: other }
                    }
                    throw x
                })
            return retryOperation(tryData)
                .catch(rejectWithValue)
        },
    )

/**
 * Perform an edit to values from the administration panel
 */
export const updateData = createAsyncThunk<AdminDelta,
    { view: AdminView, delta: AdminDelta, app: AppData, options: UrlOption }, { state: RootState }>(
        'admin/updateData',
        async (params, { dispatch, getState, rejectWithValue }) => {
            await new Promise((resolve) => setTimeout(resolve, 500))
            let { view, delta, app, options } = params
            let newDelta = null
            let ret = null

            // Edge case since media editor is a separate thing
            if (view == AdminView.Media) {
                view = 'mediatags'
                app = null
            }

            let key = view.singular()

            const tryUpdate = async () => {
                switch (view) {
                    case AdminView.Profile:
                        if (Object.keys(delta).length > 0) {
                            await putUser(getState().user.data.id, delta)
                            dispatch(updateUser(delta))
                        }
                        newDelta = delta
                        break
                    case AdminView.Pricing:
                        if (delta.length > 0) {
                            await putPricing(app, delta)
                        }
                        newDelta = delta
                        break
                    case AdminView.Availability:
                        if (delta.length > 0) {
                            await putAvailability(app, delta)
                        }
                        newDelta = delta
                        break
                    case AdminView.Worksheets:
                        if (delta.length > 0) {
                            await putWorksheets(app, delta)
                        }
                        newDelta = delta
                        break
                    default:
                        ret = null
                        if (delta.id == null) {
                            if (app) {
                                ret = await postAppData(app, key, delta, options)
                            } else {
                                ret = await postData(key, delta, options)
                            }
                        } else if (app) {
                            ret = await putAppData(app, key, delta, options)
                        } else {
                            ret = await putData(key, delta, options)
                        }

                        if (ret && ret.failures && ret.failures.length > 0) {
                            if (debugErrors) {
                                throw new Error({ success: 0, message: ret.failures.map(JSON.stringify).join(','), status: 422, data: ret.failures })
                            } else {
                                throw new Error({ success: 0, message: ErrorMessage.UpdateError, status: 422 })
                            }
                        }
                        if (!ret || !('success' in ret)) {
                            throw new Error({ success: 0, message: ErrorMessage.UpdateError, status: 422 })
                        }
                        const { success, message, status, ...other } = ret
                        if (Object.keys(other).length == 1 && view != AdminView.General) {
                            newDelta = Object.values(other)[0]
                        } else {
                            newDelta = other
                        }
                        break
                }

                dispatch(resolvePending(true))
                return { view, delta: newDelta }
            }

            return retryOperation(tryUpdate)
                .catch((e) => {
                    dispatch(resolvePending(e))
                    return rejectWithValue({ view, error: e })
                })
        },
    )

export const removeData = createAsyncThunk<NestedDict,
    { app: AppData, view: AdminView, id: string }, { state: RootState }>(
        'admin/removeData',
        async ({ app, view, id }, { dispatch, rejectWithValue }) => {
            const tryRemove = () => {
                let prom = null
                let key = view.singular()
                if (app) {
                    prom = deleteAppData(app, key, id)
                } else {
                    prom = deleteData(key, id)
                }
                return prom.then((x) => {
                    dispatch(resolvePending(true))
                    if (x.id) {
                        return { view, id: x.id }
                    }
                    return { view, id: null }
                })
            }
            return retryOperation(tryRemove)
                .catch((x) => {
                    dispatch(resolvePending(false))
                    return rejectWithValue(x)
                })
        },
    )

/**
 * Update Project
 */
export const updateProject = createAsyncThunk<AdminDelta,
    { view: AdminView, data: AppData }, { state: RootState }>(
        'admin/updateProject',
        ({ view, data }, { dispatch, rejectWithValue }) => {
            const tryUpdate = async () => {
                const ret = await putData(AdminView.Apps, data)
                const newDelta = ret[AdminView.Apps]
                dispatch(resolvePending(true))
                return { view, data: newDelta, success: true }
            }
            return retryOperation(tryUpdate)
                .catch((e) => {
                    dispatch(resolvePending(e))
                    return rejectWithValue({ view: view, error: e })
                })
        },
    )

/**
 * Add page
 */
export const upsertPage = createAsyncThunk<Dict,
    { app: AppData, organization: OrganizationData, page: PageData }, { state: RootState }>(
        'admin/upsertPage',
        ({ app, organization, page }, { getState, dispatch, rejectWithValue }) => {
            const tryAddPage = () => {
                let pageArr = Array.isArray(page) ? page : [page]
                let prom = Promise.all(pageArr.map((page) => {
                    const newPage = {
                        ...page,
                    }
                    if (page.pageType) {
                        const pageTypeData = Object.values(getState().app.config.pageTypes).find((x) => x.name == page.pageType)
                        if (!pageTypeData) {
                            throw new Error(`Missing config for ${pageType}`)
                        }
                        if (!newPage.name) {
                            newPage.name = page.pageType.toTitleCase()
                        }
                        if (!newPage.link) {
                            newPage.link = page.pageType
                        }
                        newPage.pageTypeId = pageTypeData.id
                    }
                    let prom = null
                    if (app) {
                        newPage.appId = app.meta.id
                        if (newPage.id) {
                            return putAppData(app, 'page', newPage)
                        } else {
                            return postAppData(app, 'page', newPage)
                        }
                    } else if (organization) {
                        newPage.organizationId = organization.id
                        if (newPage.id) {
                            return putOrganizationData(organization, 'page', newPage)
                        } else {
                            return postOrganizationData(organization, 'page', newPage)
                        }
                    }
                }))
                if (!prom) {
                    throw new Error('No pages to add')
                }
                return prom.then((x) => {
                    let ret = Array.isArray(x) ? x[0] : x
                    if (ret && ret.success) {
                        dispatch(resolvePending(true))
                    } else {
                        dispatch(resolvePending(ret))
                    }
                    return x
                })
            }
            return retryOperation(tryAddPage)
                .catch((x) => {
                    dispatch(resolvePending(x))
                    return rejectWithValue(x)
                })
        },
    )

export const removePage = createAsyncThunk<Dict,
    { app: AppData, organizaiton: OrganizationData, page: PageData }, { state: RootState }>(
        'admin/removePage',
        ({ app, organization, page }, { getState, dispatch, rejectWithValue }) => {
            if (!app && organization) {
                return deleteOrganizationData(organization, 'page', page.id)
            } else {
                return deleteAppData(app, 'page', page.id)
            }
        })

/**
 * Media
 */
export const addMediaFiles = createAsyncThunk<Dict,
    { files: Array, onUploadProgress: () => void, options: SegmentOptions }, { state: RootState }>(
        'admin/addMediaFiles',
        ({ files, onUploadProgress, options }, { dispatch, rejectWithValue }) => {
            const tryPostMediaFiles = () => {
                return postMediaFiles(files, onUploadProgress, options)
            }
            return retryOperation(tryPostMediaFiles)
                .catch(rejectWithValue)
        },
    )

export const addMedia = createAsyncThunk<Dict,
    { media: MediaData, options: SegmentOptions }, { state: RootState }>(
        'admin/addMedia',
        ({ media, options }, { dispatch, rejectWithValue }) => {
            const tryAddMedia = () => {
                return postMedia(media, options)
            }
            return retryOperation(tryAddMedia)
                .catch(rejectWithValue)
        },
    )

export const updateMedia = createAsyncThunk<Dict,
    { data: MediaData, options: SegmentOptions }, { state: RootState }>(
        'admin/updateMedia',
        ({ data, options }, { dispatch, rejectWithValue }) => {
            const tryPutMedia = () => {
                return putMedia(data, options)
                    .then((x) => {
                        dispatch(updateProjectMedia(data))
                    })
            }
            return retryOperation(tryPutMedia)
                .catch(rejectWithValue)
        },
    )

export const removeMedia = createAsyncThunk<Dict,
    { media: string[], options: SegmentOptions }, { state: RootState }>(
        'admin/removeMedia',
        ({ media, options }, { dispatch, rejectWithValue }) => {
            const tryRemoveMedia = () => {
                return deleteMedia(media, options)
                    .then(() => {
                        dispatch(deleteProjectMedia(media))
                    })
            }
            return retryOperation(tryRemoveMedia)
                .catch(rejectWithValue)
        },
    )


export const generateMediaThumbnails = createAsyncThunk<Dict,
    { media: MediaData, options: SegmentOptions }, { state: RootState }>(
        'admin/generateMediaThumbnails',
        ({ media, options, file }, { dispatch, rejectWithValue }) => {
            const tryGenMedia = () => {
                if (file) {
                    return postMediaThumbnails(media, options, file)
                } else {
                    return getMediaThumbnails(media, options, file)
                }
            }
            return retryOperation(tryGenMedia)
                .catch(rejectWithValue)
        },
    )

export const exportMediaFile = createAsyncThunk<Dict,
    { media: MediaData, options: SegmentOptions }, { state: RootState }>(
        'admin/exportMediaFile',
        ({ media, options }, { dispatch, rejectWithValue }) => {
            const tryExportFile = () => {
                return getMediaExport(media, options)
            }
            return retryOperation(tryExportFile)
                .catch(rejectWithValue)
        },
    )

export const upsertMap = createAsyncThunk<Dict,
    MapData, { state: RootState }>(
        'admin/upsertMap',
        (map, { dispatch, rejectWithValue }) => {
            const tryUpdateMap = () => {
                if (map.id) {
                    return putMap(map)
                } else {
                    return postMap(map)
                }
            }
            return retryOperation(tryUpdateMap)
                .catch(rejectWithValue)
        },
    )


export const retrieveMap = createAsyncThunk<Dict,
    { link: string, options: SegmentOptions }, { state: RootState }>(
        'admin/retrieveMap',
        ({ link, options }, { getState, dispatch, rejectWithValue }) => {
            const tryRetrieveMap = () => {
                return getMap(link, options)
            }

            return retryOperation(tryRetrieveMap)
                .catch((x) => {
                    return rejectWithValue(x)
                })
        },
    )

export const pollJob = createAsyncThunk<Dict,
    { app: AppData, pageType: PageType }, { state: RootState }>(
        'admin/pollJob',
        (jobId, { rejectWithValue }) => {
            const tryPollJob = () => getJob(jobId)
            return retryOperation(tryPollJob)
                .catch(rejectWithValue)
        },
    )

export const startJob = createAsyncThunk<Dict,
    { app: AppData, pageType: PageType }, { state: RootState }>(
        'admin/startJob',
        (jobId, { rejectWithValue }) => {
            const tryStartJob = () => {
                return postStartJob(jobId)
                    .then(() => {
                        return true
                    })
            }
            return retryOperation(tryStartJob)
                .catch(rejectWithValue)
        },
    )

export const addMediaTag = createAsyncThunk<Dict,
    { data: MediaTagData, options: SegmentOptions }, { state: RootState }>(
        'admin/addMediaTag',
        ({ data, options }, { rejectWithValue }) => {
            const tryAddMediaTag = () => {
                if (data.id) {
                    return putMediaTag(data, options)
                } else {
                    return postMediaTag(data, options)
                }
            }
            return retryOperation(tryAddMediaTag)
                .catch(rejectWithValue)
        },
    )

export const removeMediaTag = createAsyncThunk<Dict,
    { data: MediaTagData, options: SegmentOptions }, { state: RootState }>(
        'admin/removeMediaTag',
        ({ data, options }, { rejectWithValue }) => {
            const tryRemoveMediaTag = () => deleteMediaTag(data, options)
            return retryOperation(tryRemoveMediaTag)
                .catch(rejectWithValue)
        },
    )


/**
 * Galleries
 */
export const upsertGallery = createAsyncThunk<Dict,
    { data: MediaData, options: SegmentOptions }, { state: RootState }>(
        'admin/upsertGallery',
        ({ data, options }, { dispatch, rejectWithValue, getState }) => {
            const tryUpsertGallery = () => {
                let prom = null
                if (data.id) {
                    prom = putGallery(data, options)
                } else {
                    prom = postGallery(data, options)
                }
                return prom.then((x) => {
                    // If is current project
                    // dispatch(updateProject(x))
                    // if (getState().project?.id === getState().app.projectCurrent[0]?.id) {
                    // dispatch(updateGallery(x))
                    // }
                    // if (options.organization) {
                    //     const newGalleries = options.organizations.galleries
                    //     const idx = newGalleries.findIndex((x) => x.id === data.id)
                    //     if (idx !== -1) {
                    //         newGalleries[idx] = x
                    //     } else {
                    //         newGalleries.push(x)
                    //     }
                    //     dispatch(updatePrompt({ organization: { ...options.organization, galleries: newGalleries } }))
                    // }
                    return x
                })
            }
            return retryOperation(tryUpsertGallery)
                .catch(rejectWithValue)
        },
    )

export const removeGallery = createAsyncThunk<Dict,
    { data: GalleryData, options: SegmentOptions }, { state: RootState }>(
        'admin/deleteGallery',
        ({ data, options }, { rejectWithValue }) => {
            const tryDeleteGallery = () => deleteGallery(data, options)
            return retryOperation(tryDeleteGallery)
                .catch(rejectWithValue)
        },
    )

/**
 * Admin functions
 */
export const retrieveLogList = createAsyncThunk<Dict, LogType, { state: RootState }>(
    'admin/retrieveLogList',
    (logType, { rejectWithValue }) => {
        const tryRetrieveLogs = () => getLogList(logType)
        return retryOperation(tryRetrieveLogs)
            .catch(rejectWithValue)
    },
)
export const retrieveLog = createAsyncThunk<Dict, { type: LogType, filename: string }, { state: RootState }>(
    'admin/retrieveLog',
    ({ logType, filename }, { rejectWithValue }) => {
        const tryRetrieveLog = () => getLog(logType, filename)
        return retryOperation(tryRetrieveLog)
            .catch(rejectWithValue)
    },
)

export const runOperation = createAsyncThunk<Dict, { operation: AdminOperation, data: Dict }, { state: RootState }>(
    'admin/runOperation',
    ({ operation, data }, { dispatch, rejectWithValue }) => {
        const tryRunOperation = () => postRunOperation(operation, data)
        return retryOperation(tryRunOperation)
            .then((x) => {
                dispatch(resolvePending(x))
            }).catch(() => {
                dispatch(resolvePending(x))
                rejectWithValue(x)
            })
    },
)

/**
 * Content
 */
export const upsertContent = createAsyncThunk<Dict,
    { app: AppData, organization: OrganizationData, data: CustomContentData }, { state: RootState }>('admin/upsertContent',
        ({ app, organization, data }, { dispatch, rejectWithValue }) => {
            const tryCreate = async () => {
                return postContent(app, organization, data)
                    .then((x) => {
                        if (x.data) {
                            // dispatch(updateAppContent(x.data))
                            // dispatch(updateEditContent(x.data))
                            return x.data
                        } else {
                            return rejectWithValue(x)
                        }

                    })
            }
            return retryOperation(tryCreate)
                .catch((e) => {
                    dispatch(resolvePending(e))
                    return rejectWithValue({ error: e })
                })
        },
    )

export const removeContent = createAsyncThunk<Dict,
    { app: AppData, organization: OrganizationData, data: CustomContentData }, { state: RootState }>(
        'admin/removeContent',
        ({ app, organization, data }, { dispatch, rejectWithValue }) => {
            const tryRemove = async () => {
                return deleteContent(app, organization, data)
                    .then((x) => {
                        if (x.success) {
                            // dispatch(removeAppContent(data))
                            return x.data
                        } else {
                            return rejectWithValue(x)
                        }
                    })
            }
            return retryOperation(tryRemove)
                .catch((e) => {
                    dispatch(resolvePending(e))
                    return rejectWithValue({ error: e })
                })
        },
    )

/**
 * Location
 */
export const createLocation = createAsyncThunk<Dict,
    { app: AppData, organization: OrganizationData, data: CustomContentData }, { state: RootState }>(
        'admin/createLocation',
        ({ app, organization, data }, { dispatch, rejectWithValue }) => {
            const tryCreate = async () => {
                return postLocation(app, organization, data)
                    .then((x) => {
                        if (x.data) {
                            if (app) {
                                dispatch(updateAppLocation(x.data))
                            } else {
                                dispatch(updateOrganizationLocation(x.data))
                            }
                            dispatch(updatePromptLocation(x.data))
                            return x.data
                        } else {
                            return rejectWithValue(x)
                        }

                    })
            }
            return retryOperation(tryCreate)
                .catch((e) => {
                    dispatch(resolvePending(e))
                    return rejectWithValue({ error: e })
                })
        },
    )

export const removeLocation = createAsyncThunk<Dict,
    { app: AppData, organization: OrganizationData, data: CustomContentData }, { state: RootState }>(
        'admin/removeLocation',
        ({ app, organization, data }, { dispatch, rejectWithValue }) => {
            const tryRemove = async () => {
                return deleteLocation(app, organization, data)
                    .then((x) => {
                        if (x.success) {
                            dispatch(removeAppLocation(data))
                            return x.data
                        } else {
                            return rejectWithValue(x)
                        }

                    })
            }
            return retryOperation(tryRemove)
                .catch((e) => {
                    dispatch(resolvePending(e))
                    return rejectWithValue({ error: e })
                })
        }
    )


/**
 * Slice +  sync actions/reducers
 */
const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        // Whether a change is pending in the admin panel
        setChangePending(state: AdminState, action: PayloadAction<boolean>) {
            state.changePending = action.payload
        },
        // Set a pending delta change in admin panel
        setDelta(state: AdminState, action: PayloadAction<AdminDelta>) {
            state.delta = action.payload
        },
        // Reset admin delta
        resetDelta(state: AdminState) {
            setDefaultDelta(state)
            state.changePending = false
        },
        resetAdminError(state: AdminState, action: PayloadAction<AdminView>) {
            if (state.error && action.payload in state.error) {
                delete state.error[action.payload]
            }
        },
        loadData(state: AdminState, action) {
            const { view, data } = action.payload
            state[view] = data
        },
        clearAdminProject(state: AppState) {
            state.project = null
        },
        updateEditContent(state: AppState, action: PayloadAction<ContentData>) {
            const content = action.payload
            if (state.editContent?.id === content.id || state.editContent == null) {
                state.editContent = { ...content, refresh: Date.now() }
            }
        },
        setEditContent(state: AdminState, action: PayloadAction<ContentData>) {
            state.editContent = action.payload
            if (state.editContent) {
                state.editContent.refresh = Date.now()
            }
            // Convert from hardcoded format
            if (!state.editContent?.id || state.editContent?.id?.length < 36) {
                if ('idx' in state.editContent) {
                    state.editContent.order = state.editContent.idx
                    delete state.editContent.idx
                }

                if (state.editContent?.media && !Array.isArray(state.editContent.media)) {
                    state.editContent.media = [state.editContent.media]
                }
                if (state.editContent?.links && !Array.isArray(state.editContent.links)) {
                    state.editContent.links = [state.editContent.links]
                }
                if (state.editContent?.location && !Array.isArray(state.editContent.location)) {
                    logger.info("Existing location", state.editContent.location)
                    delete state.editContent.location
                }
                if (state.editContent && 'type' in state.editContent) {
                    state.editContent.dynamicContentTypeId = state.editContent.type
                    delete state.editContent.type
                }
            }
            state.editInline = true
            saveState(state)
        },
        clearEditContent(state: AdminState) {
            state.editContent = null
            // state.editInline = false
        },
        setEditInline(state: AdminState, action: PayloadAction<boolean>) {
            state.editInline = action.payload
            saveState(state)
        },
        setProject(state: AdminState, action: PayloadAction<ProjectData>) {
            state.project = action.payload
        },
        setOrganization(state: AdminState, action: PayloadAction<ProjectData>) {
            state.organization = action.payload
        },
    },
    extraReducers: (builder) => {
        // Prepare admin page for loading
        builder.addCase(initializeAdmin.pending, (state: AdminState, action: { meta: { arg: { appLink: string, organization: OrganizationData } } }) => {

        })
        // Load main page state
        builder.addCase(
            initializeAdmin.fulfilled,
            (state: AdminState, action: PayloadAction<AdminState>) => {
                state.config = action.payload.config
                if (action.meta.arg != null) {
                    const { appLink, options } = action.meta.arg
                    const { organization, domain } = options

                    document.title = 'Home Gyde Administration'

                    if (domain) {
                        state.customDomain = domain
                    }

                    if (organization && !appLink) {
                        let org = state.config.organizations.find((x) => x.link == ((domain && domain.organization) || organization))
                        if (org) {
                            document.title = `${org.name} Administration`
                        }
                    }

                    if (appLink || domain?.app) {
                        const app = action.payload.apps.find((x) => x.link === ((domain && domain.app) || appLink))
                        if (app) {
                            document.title = `${app.name} Administration`
                        }
                    }
                }

                state.userApps = action.payload.apps
                if (action.payload.organization) {
                    state.organization = action.payload.organization
                } else if (action.meta.arg.organization) {
                    state.organization = action.meta.arg.organization
                }
                state.error = {}

                const media = action.payload.media
                if (state.organization) {
                    if (state.organization.faviconMediaId && state.organization.faviconMediaId in media) {
                        const faviconMedia = media[state.organization.faviconMediaId]

                        const url = getMediaLink(faviconMedia.link, { organization: state.organization })
                        fnc.changeFavicon(url)
                        return
                    }
                }
                fnc.changeFavicon('/assets/favicon.png')
            },
        )
        // Failed to load main page
        builder.addCase(
            initializeAdmin.rejected,
            (state: AdminState, action: PayloadAction<Error>) => {
                if (action) {
                    logger.error(action.payload)
                }
                state.error.general = ErrorMessage.LoadError
            },
        )
        // Apply retrieved AdminState
        builder.addCase(
            initializeAdminProject.fulfilled,
            (state: AdminState, action: PayloadAction<AdminState>) => {
                // Find in list of apps
                const { success, message, status, media, organization, ...info } = action.payload

                if (organization) {
                    state.organization = organization
                } else if (action.payload.organizations && action.payload.organizations.length > 0) {
                    state.organization = state.config.organizations.find((x) => x.id == action.payload.organizations[0])
                }

                if (!state.organization) {
                    state.organization = state.config.organizations.find((x) => x.link == 'homegyde')
                }

                state.project = setupApp(info, false, state.config)
            },
        )
        builder.addCase(
            initializeAdminProject.rejected,
            (state: AdminState) => {
                state.error = ErrorMessage.LoadError
            },
        )
        // Begin edit, flag in progress
        builder.addCase(updateData.pending, (state: AdminState) => {
            state.editPending = true
            state.error = {}
        })
        // Edit failed
        builder.addCase(updateData.rejected, (state: AdminState, action: PayloadAction<Error>) => {
            logger.error(action.payload)
            state.editPending = false
            if (action.payload && action.payload.view && action.payload.error && action.payload.error.message) {
                const { view, error } = action.payload
                state.error[view] = error.message
            }
        })
        // Edit successful, apply delta to state
        builder.addCase(updateData.fulfilled, (state: AdminState, action: PayloadAction<{ view: AdminView, delta: AdminDelta }>) => {
            state.editPending = false
            const { view, delta } = action.payload
            if (view in state.error) {
                delete state.error
            }

            switch (view) {
                case AdminView.Profile:
                    return

                case AdminView.Availability:
                    for (let i = 0; delta && i < delta.length; i += 1) {
                        try {
                            const unit = state.project.units.find((x) => x.id === delta[i].unitId)
                            unit.availabilityStateId = delta[i].availabilityStateId
                            state.project.maps.unit[unit.id] = unit
                        } catch (e) {
                            logger.error('Error updating units', e)
                        }
                    }
                    break
                case AdminView.Pricing:
                    for (let i = 0; delta && i < delta.length; i += 1) {
                        try {

                            // Replace / remove / add override values
                            if (delta[i].unitId != null) {
                                const floorplanIndex = state.project.floorplans
                                    .findIndex((x) => x.id === delta[i].floorplanId)
                                const newFloorplan = { ...state.project.floorplans[floorplanIndex] }
                                state.project.floorplans[floorplanIndex] = newFloorplan
                                const variation = newFloorplan.variations
                                    .find((x) => x.id === delta[i].variationId)
                                const overrideIdx = variation.unitPriceOverrides.findIndex((x) => x.unitId == delta[i].unitId)
                                if (overrideIdx != -1) {
                                    if (!delta[i].price) {
                                        variation.unitPriceOverrides.splice(overrideIdx, 1)
                                    } else {
                                        variation.unitPriceOverrides[overrideIdx].price = delta[i].price
                                    }
                                } else {
                                    variation.unitPriceOverrides.push({ price: delta[i].price, unitId: delta[i].unitId })
                                }
                            } else {
                                // Replace variation price
                                const floorplanIndex = state.project.floorplans
                                    .findIndex((x) => x.id === delta[i].floorplanId)
                                const newFloorplan = { ...state.project.floorplans[floorplanIndex] }
                                state.project.floorplans[floorplanIndex] = newFloorplan
                                const variation = newFloorplan.variations
                                    .find((x) => x.id === delta[i].variationId)
                                variation.price = delta[i].price
                            }
                        } catch (e) {
                            logger.error('Error updating floorplans', e)
                        }
                    }
                    break
                case AdminView.Sitemap: {
                    const { data, failures } = action.payload.delta
                    state.project.sitemaps = data
                    /*const originalId = action.meta.arg.delta.id
                    // Update sitemap
                    const idx = state.project.sitemaps.findIndex((x) => x.id == originalId)
                    if (idx === -1) {
                        state.project.sitemaps.push(delta.data)
                    } else {
                        state.project.sitemaps[idx] = delta
                    }*/
                    break
                }
                case AdminView.Units: {
                    const { unit: units, sites, buildings, buildingTypes } = action.payload.delta
                    state.project = {
                        ...state.project,
                        units,
                        sites,
                        buildings,
                        buildingTypes,
                    }
                    setupAppMaps(state.project)
                    break
                }
                case AdminView.Floorplans: {
                    const { floorplan: floorplans } = action.payload.delta
                    state.project = { ...state.project, floorplans }
                    setupAppMaps(state.project)
                    break
                }
                case AdminView.General: {
                    if (action.payload.delta.pages || action.payload.delta.phases || action.payload.delta.termsAndConditions) {
                        const { pages, phases, termsAndConditions } = action.payload.delta
                        state.project = { ...state.project }
                        if (pages != null) {
                            state.project.pages = setupPages(pages)
                        }
                        if (phases != null) {
                            state.project.phases = phases
                        }
                        if (termsAndConditions != null) {
                            state.project.termsAndConditions = termsAndConditions
                        }
                    }
                    if (action.payload.delta.colorSchemes || action.payload.delta.themes) {
                        const { colorSchemes, themes } = action.payload.delta
                        if (colorSchemes) {
                            state.config.colorSchemes = colorSchemes
                        }
                        if (themes) {
                            state.config.themes = themes
                        }
                    }
                    break
                }
                case AdminView.Spin: {
                    if (action.payload.delta.spin) {
                        state.delta.spin.spins = action.payload.delta.spin
                    }
                    break
                }
                case AdminView.Upgrades: {
                    const { failures, messages, status, success, ...upgrades } = action.payload.delta
                    const finalUpgrades = setupUpgrades(upgrades)
                    state.delta.upgrades = { ...state.delta.upgrades, ...finalUpgrades }
                    break
                }
                case AdminView.UpgradesConfig: {
                    const { failures, messages, status, success, ...upgrades } = action.payload.delta
                    const finalUpgrades = setupUpgrades(upgrades)
                    state.delta = { ...state.delta, 'upgrades-config': { ...state.delta['upgrades-config'], ...finalUpgrades } }
                    state.lastSave = Date.now()
                    break
                }
                default: {
                    if (!delta || delta.id == null) {
                        logger.error(`Invalid ${view}`, delta)
                        return
                    }
                    // Find user and update relevantfields
                    let newData = [...state[view]]
                    const index = newData.findIndex((x) => x.id == delta.id)
                    if (index === -1) {
                        newData.push(delta)
                    } else {
                        newData[index] = {
                            ...newData[index],
                            ...delta,
                        }
                    }
                    state[view] = newData
                    break
                }
            }
        })
        // Begin edit, flag in progress
        builder.addCase(updateProject.pending, (state: AdminState) => {
            state.editPending = true
            state.error = {}
        })
        // Edit failed
        builder.addCase(updateProject.rejected, (state: AdminState, action: PayloadAction<Error>) => {
            logger.error(action.payload)
            state.editPending = false
            if (action.payload && action.payload.view && action.payload.error && action.payload.error.message) {
                const { view, error } = action.payload
                state.error[view] = error.message
            }
        })
        // Edit successful, apply delta to state
        builder.addCase(updateProject.fulfilled, (state: AdminState, action: PayloadAction<{ view: AdminView, delta: AdminDelta }>) => {
            state.editPending = false
            const { view, data } = action.payload
            if (view in state.error) {
                delete state.error
            }

            state.project.meta = data
            setupAppMeta(state.project)
        })

        // Get data
        builder.addCase(retrieveData.fulfilled, (state: AdminState, action) => {
            const { view, data } = action.payload
            const key = view.plural()
            if (Array.isArray(data)) {
                state[key] = data
            } else if (typeof data == 'object') {
                Object.keys(data).forEach((x) => {
                    state[x] = data[x]
                })
            } else {
                logger.error('Invalid data type')
            }
        })
        builder.addCase(retrieveData.rejected, (state: AdminState, action) => {
            state.user = { error: ErrorMessage.DataError }
        })
        builder.addCase(removeData.fulfilled, (state: AdminState, action) => {
            const { view, id } = action.payload
            const key = view.plural()
            if (Array.isArray(state[view])) {
                state[key] = removeFromList(id, state[view])
            }
        })
        builder.addCase(removeData.rejected, (state: AdminState, action) => {
            state.error[action.meta.arg.view] = ErrorMessage.DeleteError
        })
        // Get analytics data
        builder.addCase(retrieveAnalytics.pending, (state: AdminState, action) => {
            state.analytics = {}
        })
        builder.addCase(retrieveAnalytics.fulfilled, (state: AdminState, action) => {
            if (action.meta.arg.csv) {
                return
            }
            state.analytics = {}
            const keys = Object.values(Analytics)
            for (let i = 0; i < keys.length; i += 1) {
                if (keys[i] in action.payload) {
                    state.analytics[keys[i]] = action.payload[keys[i]]
                }
            }
        })
        builder.addCase(retrieveAnalytics.rejected, (state: AdminState, action) => {
            state.analytics = { error: ErrorMessage.DataError }
        })
        builder.addCase(upsertPage.fulfilled, (state: AdminState, action: PayloadAction<PageData>) => {
            const pageArr = Array.isArray(action.payload) ? action.payload : [action.payload]
            const pages = action.meta.arg.app ? state.project.pages : state.organization.pages
            const page = action.payload
            const newPages = [...pages]
            pageArr.forEach((x) => {
                const { page } = x
                // Convert ID to type
                const configPage = state.config.pageTypes[page.pageTypeId]
                page.pageType = configPage.name
                // delete page.pageTypeId

                const idx = pages.findIndex((x) => {
                    if (x.id == null) {
                        return x.pageType = page.pageType
                    } else {
                        return x.id == page.id
                    }
                })

                if (idx != -1) {
                    newPages[idx] = page
                } else {
                    newPages.push(page)
                }
            })
            if (action.meta.arg.app) {
                state.project.pages = newPages
                action.payload.project = fnc.copyObj(state.project)
            } else {
                state.organization.pages = newPages
                state.organization.modified = Date.now()
                action.payload.organization = fnc.copyObj(state.organization)
            }
        })
        builder.addCase(removePage.fulfilled, (state: AdminState, action: PayloadAction<string>) => {
            const pages = action.meta.arg.app ? state.project.pages : state.organization.pages
            const newPages = [...pages]
            const { page } = action.meta.arg
            const pageIdx = newPages.findIndex((x) => x.id == page.id)
            if (pageIdx !== -1) {
                newPages.splice(pageIdx, 1)
            }
            if (action.meta.arg.app) {
                state.project.pages = newPages
            } else {
                state.organization.pages = newPages
                state.organization.modified = Date.now()
            }
        })
        builder.addCase(addMediaTag.fulfilled, (state: AdminState, action: PayloadAction<MediaTagData>) => {
            const { mediaTag } = action.payload
            let tags = []
            if (action.meta.arg.options.app) {
                tags = state.project.meta.mediaTags
            } else if (action.meta.arg.options.builder) {
                tags = action.meta.arg.options.builder.mediaTags
            } else if (action.meta.arg.options.organization) {
                tags = action.meta.arg.options.organization.mediaTags
            }
            const idx = tags.findIndex((x) => x.id == mediaTag.id)
            let newTags = [...tags]
            if (idx == -1) {
                newTags.push(mediaTag)
            } else {
                newTags[idx] = mediaTag
            }
            if (action.meta.arg.options.app) {
                state.project.meta.mediaTags = newTags
                state.project = { ...state.project }
            } else if (action.meta.arg.options.builder) {
                const idx = state.builders.findIndex((x) => x.id == action.meta.arg.options.builder.id)
                if (idx != -1) {
                    state.builders[idx].mediaTags = newTags
                }
            } else if (action.meta.arg.options.organization) {
                const idx = state.organizations.findIndex((x) => x.id == action.meta.arg.options.organization.id)
                if (idx != -1) {
                    state.organizations[idx].mediaTags = newTags
                }
            }
        })

        builder.addCase(removeMediaTag.fulfilled, (state: AdminState, action: PayloadAction<MediaTagData>) => {
            let tags = []
            if (action.meta.arg.options.app) {
                tags = state.project.meta.mediaTags
            } else if (action.meta.arg.options.builder) {
                tags = action.meta.arg.options.builder.mediaTags
            } else if (action.meta.arg.options.organization) {
                tags = action.meta.arg.options.organization.mediaTags
            }
            const idx = tags.findIndex((x) => x.id == action.meta.arg.data.id)
            if (idx != -1) {
                const newTags = [...tags]
                newTags.splice(idx, 1)
                if (action.meta.arg.options.app) {
                    state.project.meta.mediaTags = newTags
                    state.project = { ...state.project }
                } else if (action.meta.arg.options.builder) {
                    const idx = state.builders.findIndex((x) => x.id == action.meta.arg.options.builder.id)
                    if (idx != -1) {
                        state.builders[idx].mediaTags = newTags
                    }
                } else if (action.meta.arg.options.organization) {
                    const idx = state.organizations.findIndex((x) => x.id == action.meta.arg.options.organization.id)
                    if (idx != -1) {
                        state.organizations[idx].mediaTags = newTags
                    }
                }
            }
        })
        // Galleries
        builder.addCase(upsertGallery.fulfilled, (state: AdminState, action: PayloadAction<MediaTagData>) => {
            const { gallery } = action.payload
            let galleries = []
            if (action.meta.arg.options.app) {
                galleries = state.project?.galleries
            } else if (action.meta.arg.options.builder) {
                galleries = action.meta.arg.options.builder.galleries
            } else if (action.meta.arg.options.organization) {
                galleries = action.meta.arg.options.organization.galleries
            }
            if (!galleries?.length) {
                return
            }

            const idx = galleries.findIndex((x) => x.id == gallery.id)
            let newGalleries = [...galleries]
            if (idx == -1) {
                newGalleries.push(gallery)
            } else {
                newGalleries[idx] = gallery
            }
            if (action.meta.arg.options.app) {
                state.project.galleries = newGalleries
                state.project = { ...state.project }
            } else if (action.meta.arg.options.builder) {
                const idx = state.config.builders.findIndex((x) => x.id == action.meta.arg.options.builder.id)
                if (idx != -1) {
                    state.config.builders[idx].galleries = newGalleries
                    state.config = { ...state.config }
                }
            } else if (action.meta.arg.options.organization) {
                const idx = state.config.organizations.findIndex((x) => x.id == action.meta.arg.options.organization.id)
                if (idx != -1) {
                    state.config.organizations[idx].galleries = newGalleries
                    state.config = { ...state.config }
                }
                if (action.meta.arg.options.organization.id == state.organization.id) {
                    state.organization = { ...state.organization, galleries: newGalleries, modified: Date.now() }
                }
            }
        })
        builder.addCase(removeGallery.fulfilled, (state: AdminState, action: PayloadAction<MediaTagData>) => {
            let galleries = []
            if (action.meta.arg.options.app) {
                galleries = state.project.galleries
            } else if (action.meta.arg.options.builder) {
                galleries = action.meta.arg.options.builder.galleries
            } else if (action.meta.arg.options.organization) {
                galleries = action.meta.arg.options.organization.galleries
            }
            const idx = galleries.findIndex((x) => x.id == action.meta.arg.data.id)
            if (idx != -1) {
                const newGalleries = [...galleries]
                newGalleries.splice(idx, 1)
                if (action.meta.arg.options.app) {
                    state.project.galleries = newGalleries
                    state.project = { ...state.project }
                } else if (action.meta.arg.options.builder) {
                    const idx = state.config.builders.findIndex((x) => x.id == action.meta.arg.options.builder.id)
                    if (idx != -1) {
                        state.config.builders[idx].galleries = newGalleries
                        state.config = { ...state.config }
                    }
                } else {
                    const idx = state.config.organizations.findIndex((x) => x.id == action.meta.arg.options.organization.id)
                    if (idx != -1) {
                        state.config.organizations[idx].galleries = newGalleries
                        state.config = { ...state.config }
                    }
                    if (action.meta.arg.options.organization.id == state.organization.id) {
                        state.organization = { ...state.organization, galleries: newGalleries, modified: Date.now() }
                    }
                }
            }
        })
        builder.addCase(upsertContent.fulfilled, (state: AdminState, action: PayloadAction<ContentData>) => {
            if (action.meta.arg.app) {
                if (!state.project) return
                const idx = state.project?.dynamicContent.findIndex((x) => x.id == action.payload.id)
                state.project = { ...state.project }
                // state.editContent = action.payload
                if (idx == -1) {
                    state.project.dynamicContent.push(action.payload)
                } else {
                    state.project.dynamicContent[idx] = action.payload
                }
            } else if (action.meta.arg.organization) {
                const idx = state.organization?.dynamicContent.findIndex((x) => x.id == action.payload.id)
                state.organization = { ...state.organization }
                // state.editContent = action.payload
                if (idx == -1) {
                    state.organization.dynamicContent.push(action.payload)
                } else {
                    state.organization.dynamicContent[idx] = action.payload
                }
                state.organization.modified = Date.now()
            }
            // setEditContent(action)
        })
        builder.addCase(removeContent.fulfilled, (state: AdminState, action: PayloadAction<ContentData>) => {
            if (action.meta.arg.app) {
                if (!state.project) return
                state.project = { ...state.project }
                const idx = state.project?.dynamicContent.findIndex((x) => x.id == action.meta.arg.data.id)
                if (idx != -1) {
                    state.project.dynamicContent.splice(idx, 1)
                }
            } else if (action.meta.arg.organization) {
                if (!state.organization) return
                state.organization = { ...state.organization }
                const idx = state.organization?.dynamicContent.findIndex((x) => x.id == action.meta.arg.data.id)
                if (idx != -1) {
                    state.organization.dynamicContent.splice(idx, 1)
                }
                state.organization.modified = Date.now()
            }
        })
        builder.addCase(createLocation.fulfilled, (state: AdminState, action: PayloadAction<LocationData>) => {
            // See if 
            let source = null
            if (action.meta.arg.app) {
                source = state.apps.find((x) => x.id == action.meta.arg.app.id)
                state.delta = { ...state.delta, apps: source }
            } else if (action.meta.arg.organization) {
                source = state.organizations.find((x) => x.id == action.meta.arg.organization.id)
                state.delta = { ...state.delta, organizations: source }
            }
            if (source) {
                source.locations.push(action.payload)
            }
        })
        builder.addCase(removeLocation.fulfilled, (state: AdminState, action: PayloadAction<LocationData>) => {
            let source = null
            if (action.meta.arg.app) {
                source = state.apps.find((x) => x.id == action.meta.arg.app.id)
                state.delta = { ...state.delta, apps: source }
            } else if (action.meta.arg.organization) {
                source = state.organizations.find((x) => x.id == action.meta.arg.organization.id)
                state.delta = { ...state.delta, organizations: source }
            }
            if (source) {
                const idx = source.locations.findIndex((x) => x.id == action.meta.arg.data.id)
                if (idx != -1) {
                    source.locations.splice(idx, 1)
                }
            }
        })
    }
})

export const {
    setChangePending,
    setDelta,
    resetDelta,
    resetAdminError,
    loadData,
    clearAdminProject,
    setEditContent,
    clearEditContent,
    updateEditContent,
    setEditInline,
    setProject,
    setOrganization
} = adminSlice.actions

export default adminSlice.reducer
